.wrapper {
  display: block;
  width: 100vw;
  height: 100vh;
  margin: auto;
  background-color: #fafafa;
  font-family: "Fira Sans", sans-serif;
}

.llogo {
  display: block;
  background-image: url(./reachLogo.png);
  width: 130px;
  height: 80px;
  background-size: cover;
  background-position: center;
  margin: auto;
  margin-bottom: 20px;
}

.loginWrap {
  display: block;

  margin: auto;

  background-color: white;
  padding: 50px 50px 30px 50px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
}
/* .Submit {
  display: block;
  margin-left: auto;
  margin-right: 0px;
  margin-top: 40px;
  font-weight: 500px;
} */

.loginWrapWrap {
  position: fixed;
  width: 500px;
  top: calc(50% - 250px);
  left: calc(50% - 250px);
}

.passWord {
  margin-top: 20px;
}

@media only screen and (max-width: 650px) {
  .loginWrapWrap {
    position: fixed;
    width: 400px;
    top: calc(45% - 200px);
    left: calc(50% - 200px);
  }
}

@media only screen and (max-width: 550px) {
  .loginWrapWrap {
    position: fixed;
    width: 350px;
    top: calc(40% - 175px);
    left: calc(50% - 175px);
  }
}

@media only screen and (max-width: 450px) {
  .loginWrapWrap {
    position: fixed;
    width: 300px;
    top: calc(40% - 150px);
    left: calc(50% - 150px);
  }

  /* .passWord1,
  .userId1 {
    font-size: 20px;
    font-weight: 500;
  } */
}
.popUp1 {
  position: fixed;
  top: 25%;
  left: calc(50% - 20vw);
  padding: 0px 0px 2px 0px;
  width: 40vw;
  border-radius: 4px;
  background-color: white;
  z-index: 1002;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
}

.overLay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1001;
  -moz-opacity: 0.8;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.false {
  display: none;
}
.true {
  display: block;
}
.popUp .chartTitle {
  width: calc(100% - 2px);
  height: 35px;
  border: solid 1px #f2f2f2;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #fafafa;
}

.popUp .chartTitleText {
  display: inline-block;
  margin: 0px 0px 0px 18px;
  line-height: 35px;
  font-size: 11.4px;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #000000;
}
.close {
  height: 35px;
  float: right;
}
.close > svg {
  height: 35px;
  cursor: pointer;
}

.resetPass {
  margin: 0px;
  text-align: right;
  font-size: 11px;
  color: #008cba;
  cursor: pointer;
}

.reset {
  display: block;
  margin-top: 30px;
  margin-left: 50px;
  font-size: 14px;
  font-weight: 600;
  color: #c8c8c8;
  letter-spacing: 0.7px;
  cursor: pointer;
}
.resetBodyWrap {
  display: block;
  margin: 0 auto;
}
.resetBody {
  display: block;
  margin: 0 auto;
  font-family: "Fira Sans", sans-serif;
  color: black;
  text-align: center;
  padding: 50px 50px 0px 50px;
}
.resetTitle {
  display: block;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-top: 50px;
  margin: 0 auto;
}

.resettext {
  display: block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.7px;
  margin: 0 auto;
  margin-top: 20px;
}

.resetInput {
}
.popup1Wrap {
  display: block;
  padding: 30px 50px 30px 50px;
}

.close {
}

@media only screen and (min-width: 1300px) {
  .popUp1 {
    max-width: 600px;
    left: calc(50% - 300px);
  }
}

@media only screen and (max-width: 1200px) {
  .popUp1 {
    width: 50vw;
    left: calc(50% - 25vw);
  }
}

@media only screen and (max-width: 950px) {
  .popUp1 {
    width: 60vw;
    left: calc(50% - 30vw);
  }
}

@media only screen and (max-width: 750px) {
  .popUp1 {
    width: 70vw;
    left: calc(50% - 35vw);
  }
}

@media only screen and (max-width: 650px) {
  .popUp1 {
    width: 80vw;
    left: calc(50% - 40vw);
  }
  .resetBody {
    padding: 50px 40px 0px 40px;
  }
  .popup1Wrap {
    padding: 30px 60px 30px 60px;
  }
}
@media only screen and (max-width: 550px) {
  .popUp1 {
    width: 80vw;
    left: calc(50% - 40vw);
  }
  .resetBody {
    padding: 50px 25px 0px 25px;
  }
  .popup1Wrap {
    padding: 30px 30px 30px 30px;
  }
}
@media only screen and (max-width: 450px) {
  .popUp1 {
    width: 90vw;
    left: calc(50% - 45vw);
  }
  .resetBody {
    padding: 50px 20px 0px 20px;
  }
  .popup1Wrap {
    padding: 30px 30px 30px 30px;
  }
}
