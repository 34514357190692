.dashboard {
    background-color: #fafafa;
    font-family: "Fira Sans", sans-serif;
}

.appBar {
    display: block;
    width: 100%;
    height: 48px;
    background-color: #212121;
}

.appBar-left {
    display: inline-block;
    height: 48px;
}
.appBar-right {
    display: inline-block;
    position: absolute;
    right: 34px;
    height: 48px;
}
.moreMenu {
    vertical-align: bottom;
    position: relative;
    display: inline-block;
    margin: 6px 0px 0px 0px;
}
.threeDot {
}
.threeDot:after {
    content: "\2807";
    font-size: 24px;
    color: white;
}
/* .dropdown {
    display: none;
    position: absolute;
    top: 24px;
    left: -75px;
    background-color: #fafafa;
    width: 80px;
    height: 70px;
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
} */

/* #dropdown {
    z-index: 999;
} */
.filSearch {
    display: block;
    margin-top: 15px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.4px;
}
.true {
    display: block;
}

.logout {
    font-size: 12px;
    line-height: 35px;
    width: 80px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
}
.goToWebsite {
    font-size: 12px;
    border-top: 0.8px solid;
    line-height: 35px;
    width: 80px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
}
.navbar {
    vertical-align: top;
    display: inline-block;
    margin: 10px 0px 0px 0px;
    padding: 0px 30px 0px 0px;
}
.navbar select {
    height: 27px;
}
.clearBut,
.subBut {
    display: inline-block;
}
.projectSelect {
    background-color: #212121;
    width: 250px;
    color: white;
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    padding: 0px 0px 1.8px 0px;
}
.projectSelect option {
    padding: 10px 0px 0px 0px;
}
.dashSelectWrapper{
  
    display: block;
    width: 100%;
    max-width: 1335px;
}
.dashSelect {
display: block;
   float: right;
   right: 50px;
   
    margin: 15px 0px 0px 0px;
    padding: 0px 30px 0px 0px;
}
.dashSelect select {
    height: 27px;
}
.dashSelectInner
{
    background-color: white;
    width: 250px;
 
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    padding: 0px 0px 1.8px 0px;
}
.dashSelectInner option {
    padding: 10px 0px 0px 0px;
}


/* .logout {
    display: inline-block;
    margin-left: 10px;
  } */

.appBar-left h1 {
    margin: 0px;
    padding: 0px;
    padding-left: 24px;
    line-height: 48px;
    text-align: center;
    color: white;
    font-size: 15.2px;
    letter-spacing: 0.46px;
    font-weight: 500;
}
.mainShell {
    width: 100%;
    display: block;
    background-color: #fafafa;
}

.main {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 1355px;
    background-color: #fafafa;
}

.drops {
    display: block;
    margin: 0 auto;
    margin-top: 20px;

    width: calc(100% - 22px);
    max-width: 1355px;
    background-color: white;
    padding-left: 20px;
    padding-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f2f2f2;
}
.info {
    display: block;
    width: 100%;
    max-width: 1355px;
    height: 100px;
    margin: 0 auto;
}

.info1 {
    display: inline-block;
    width: calc(25% - 1px);
    height: 100px;
    background-color: white;
}
.info1 .wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    justify-content: center;
    align-items: center;
}
.logo {
    width: 160px;
    height: 55px;
    margin: 0px;
    margin-right: 10px;
    margin-left: 10px;
    /* margin-top: -12.5px; */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.nestedTitle {
    font-weight: 600;
    font-size: 13px;
}

.info2 {
    display: none;
    vertical-align: top;
    width: 50%;
    height: 100px;
}

.info2 .wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.info2 .wrapper p {
    font-size: 12px;
}

.info3 {
    display: none;
    vertical-align: top;
    width: 25%;
    height: 100px;
}

.graphs {
    display: block;
    width: 100%;
}
.cardShell {
    display: block;
    background-color: #fafafa;
    width: 100%;
    
}
.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px 0px 0px 0px;
    padding: 10px 0px 0px 0px;
    background-color: #fafafa;
}
.card {
    width: 46%;
    background: white;
    border-radius: 4px;
    margin: 10px 0px 0px 0px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    height: 140px;
}
.cardTitle {
    display: block;
    width: 100%;
    height: 70px;
    line-height: 70px;
    border: solid 1px #f2f2f2;
    background-color: #fafafa;
}
.cardVal {
    display: block;
    width: 100%;
    height: 70px;
    background-color: white;
}
.cardValue {
    display: inline-block;
    width: calc(100% - 42px);
    /* margin: 10px 0px 0px 15px; */
    font-weight: 600;
    font-size: 19px;
    padding: 0 0 0 10px;
}
.cardUnit {
    display: block;
    width: 100%;
    margin: 5px 0px 0px 18px;
    font-weight: 400;
    font-size: 11px;
}

.cardTitleText {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    margin: 5px 0px 0px 18px;
    font-size: 11.4px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #000000;
}
.smallRow {
    display: block;
}
.mediumRow {
    display: block;
}
.bigRow {
    display: block;
    width: 100%;
}
.smallShell {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px 0px 0px 0px;
    padding: 10px 0px 0px 0px;
    color: white;
}
.small {
    width: 94%;

    background: white;
    border-radius: 4px;
    margin: 20px 0px 0px 0px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
}
.mediumShell {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px 0px 0px 0px;
    padding: 10px 0px 0px 0px;
    color: white;
}
.medium {
    width: 94%;

    background: white;
    border-radius: 4px;
    margin: 20px 0px 0px 0px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
}
.bigShell {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px 0px 0px 0px;
    padding: 10px 0px 0px 0px;
    color: white;
}
.big {
    width: 94%;

    background: white;
    border-radius: 4px;
    margin: 20px 0px 0px 0px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
}
.full {
    width: 96%;
}
.chartTitle {
    width: 100%;
    height: 35px;
    border: solid 1px #f2f2f2;
    background-color: #fafafa;
}
.chartTitleText {
    margin: 0px 0px 0px 18px;
    line-height: 35px;
    font-size: 11.4px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #000000;
}
.infoShell{
  
    float: right;
height: 37px;
width: 32px;
}
.infoShellCard {
    height: 37px;
    width: 32px;
    display: inline-block;
}
.infoShell > svg{
    height: 37px;

}
.infoShellCard > svg {
    position: absolute;
  
    margin-left: 7px;

    width: 18px;
    height: 18px;
}
.tooltip {
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    white-space: pre-wrap;       /* css-3 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
    word-break: break-all;
    white-space: normal;
    max-width: 300px;
    text-align: center;
}
.cardHeader {
    display: block;
    width: 100%;
    height: 40px;
    margin: 10px 0px 0px 0px;
}
.cardHeaderText {
    display: block;
    line-height: 40px;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 20px;

    font-size: 13.3px;
    font-weight: 600;
    letter-spacing: 0.73px;
}
.smallHeader {
    display: block;
    width: 100%;

    margin: 0px 0px 0px 0px;
}
.smallHeaderText {
    display: block;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 20px;

    font-size: 13.3px;
    font-weight: 600;
    letter-spacing: 0.73px;
}
.mediumHeader {
    display: block;
    width: 100%;

    margin: 0px 0px 0px 0px;
}
.mediumHeaderText {
    display: block;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 20px;

    font-size: 13.3px;
    font-weight: 600;
    letter-spacing: 0.73px;
}
.bigHeader {
    display: block;
    width: 100%;

    margin: 0px 0px 0px 0px;
}
.bigHeaderText {
    display: block;

    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 20px;

    font-size: 13.3px;
    font-weight: 600;
    letter-spacing: 0.73px;
}

.leftFooter {
    display: block;
    float: left;
    opacity: 0.8;
    font-size: 9px;
    letter-spacing: 0.2px;
    color: #000000;
    font-weight: 500;
    line-height: 34.3px;
    padding: 0px 0px 0px 40px;
}
.rightFooter {
    display: block;
    float: right;
    opacity: 0.8;
    font-size: 9px;
    letter-spacing: 0.2px;
    color: #000000;
    font-weight: 500;
    line-height: 34.3px;
    padding: 0px 60px 0px 0px;
}

svg > g > g:last-child {
    pointer-events: none;
}

.false {
    display: none;
}
.true {
    display: block;
}
.s1 {
    display: inline-block;
    width: 18.499%;
    margin: 0px 20px 0px 0px;
}
.popUp {
    position: fixed;
    top: calc(50% - 30vh);
    left: calc(50% - 47.5vw);
    padding: 0px 0px 2px 0px;
    width: 95vw;
    height: 60vh;
    border-radius: 4px;
    background-color: white;
    z-index: 98;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
}
.popUp .chartTitle {
    width: calc(100% - 2px);
    height: 35px;
    border: solid 1px #f2f2f2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #fafafa;
}

.popUp .chartTitleText {
    display: inline-block;
    margin: 0px 0px 0px 18px;
    line-height: 35px;
    font-size: 11.4px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #000000;
}
.chartTitleText {
    display: inline-block;
    margin: 0px 0px 0px 18px;
    line-height: 35px;
    font-size: 11.4px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #000000;
}
.floating {
    float: right;
    height: 35px;
}
.print {
    display: inline-block;
    height: 40px;
    cursor: pointer;
    width: 35px;
}
.print > svg {
    height: 35px;
    cursor: pointer;
}
.close {
    display: inline-block;
    height: 35px;

    cursor: pointer;
}
.close > svg {
    height: 35px;
    cursor: pointer;
}
.info2 .wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    height: 100%;
    justify-content: center;
    background: white;
    padding: 0px 10px 0px 10px;
}

.top2 {
    display: flex;
    flex-direction: row;
    padding-bottom: 6px;
    width: 100%;
}
.top2 > svg {
    width: 14px;
    height: 14px;
}
.top2 > p {
    width: 50%;
    font-weight: 600;
    margin: 0 0 0 0;
    padding: 1px 0px 0px 2px;
}
.bottom2 {
    width: 100%;
}
.bottom2 > p {
    text-align: left;
    margin: 0px;
    padding: 0px 0px 6px 2px;
}

.info3 .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    background: white;
}
.info3 .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    justify-content: flex-start;
    background: white;
}

.top3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 22px 0px 0px 20px;
}
.top3 > svg {
    width: 14px;
    height: 14px;
}
.top3 > div {
    margin: 0;
    padding: 0px 0px 0px 10px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.bottom3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 0px 0px 20px;
}
.bottom3 > svg {
    width: 14px;
    height: 14px;
}
.bottom3 > p {
    margin: 0;
    padding: 0px 0px 0px 10px;
    font-size: 12px;
}
.dateBold {
    font-weight: 600;
    margin: 0;
    padding: 0px 10px 0px 10px;
    font-size: 12px;
}
/* footer */

.dashboard {
    padding-bottom: calc(30px + 34.4px);
}
.footer {
    height: 34.3px;
    display: block;
    position: fixed;
    bottom: 0;
    width: calc(100% - 2px);
    border: solid 1px #e3e3e3;
    background-color: #f5f5f5;
}

/* pointer */
.small,
.medium,
.big,
.threeDot,
select {
    cursor: pointer;
}

/* separator */
.separator {
    margin: 20px 0px 20px 0px;
    display: block;
    width: 100%;
    max-width: 1355px;
    height: 1px;
    opacity: 0.2;
    background-color: #979797;
}

.separator1 {
    margin: 20px 0px 0px 0px;
    display: block;
    width: 100%;
    max-width: 1355px;
    height: 1px;
    opacity: 0.2;
    background-color: #979797;
}
.chartAnime {
    width: 100%;
    opacity: 0;
    animation-name: appear;
    animation-duration: 1s;
    margin: 42px 0px 0px 0px;
    animation-fill-mode: forwards;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.shelltrue {
    display: none;
}
.shellfalse {
    display: block;
}
.loaderScreen {
    z-index: 99;
    display: block;
    position: fixed;
    left: 0;
    top: 48px;
    height: 100vh;
    width: 100%;
    background-color: black;
    opacity: 0.6;
}
.loadingtext {
    z-index: 999999999999999999;
    display: block;
    position: fixed;
    width: 120px;
    font-size: 14px;
    color: white;
    opacity: 1;
    top: calc(30% - 78px);
    left: calc(50% - 47px);
}

.lds-ripple {
    z-index: 999999999999999999;
    display: block;
    position: fixed;
    width: 64px;
    height: 64px;
    top: calc(30% - 32px);
    left: calc(50% - 32px);
}
.lds-ripple div {
    position: absolute;
    border: 5px solid white;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}
.true {
    display: block;
}
.false {
    display: none;
}

.texty {
    margin: 10px 0px 10px 0px;
    font-size: 12px;
}
@media only screen and (max-width: 1373px) {
    .s1 {
        width: 18%;
    }
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1100px) {
    .nestedTitle {
        letter-spacing: 0px;
        font-size: 15px;
    }
    .logo {
        margin-left: 0px;
        margin-right: 6px;
    }
    .s1 {
        width: 17.5%;
    }
}
@media only screen and (max-width: 1000px) {
    .cardTitleText {
        margin: 5px 0px 0px 10px;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #000000;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 10px;
        font-weight: 400;
        font-size: 9px;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .cards {
        padding-top: 0px;
    }
    .nestedTitle {
        font-weight: 600;
        font-size: 14px;
    }
    .projectSelect {
        width: 250px;
    }
    .navbar {
        padding-right: 30px;
    }
    .appBar-right {
        right: 25px;
    }
    .appBar-left h1 {
        margin: 0px;
        padding: 0px;
        padding-left: 30px;
        line-height: 48px;
        text-align: center;
        color: white;
        font-size: 14px;
        letter-spacing: 0.46px;
        font-weight: 600;
    }
    .info1 {
        display: inline-block;
        width: calc(50% - 1px);
        height: 100px;
    }
    .info2 {
        display: none;
    }
    .info3 {
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 1px);
        height: 100px;

        background: white;
    }
    .info3 .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100px;
        background: white;
    }
    .info3 .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100px;
        justify-content: flex-start;
        background: white;
    }
    .leftLine {
        display: none;
    }
    .rightLine {
        display: inline-block;
        border-right: 1px black solid;
        height: 70px;
        opacity: 0.44;
        background-color: #d8d8d8;
        vertical-align: middle;
        margin: 0px 0px 70px 0px;
    }
    .top3 {
        padding-top: 32px;
    }
    .logo {
        margin-left: 5px;
        margin-right: 14px;
    }
}
@media only screen and (max-width: 900px) {
    .s1 {
        width: 22%;
    }
}
@media only screen and (max-width: 800px) {
    .s1 {
        width: 30%;
    }
}

@media only screen and (max-width: 750px) {
    .cardTitleText {
        margin: 5px 0px 0px 10px;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #000000;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 10px;
        font-weight: 400;
        font-size: 9px;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .cards {
        padding-top: 0px;
    }
    .nestedTitle {
        font-weight: 600;
        font-size: 14px;
    }
    .projectSelect {
        width: 250px;
    }
    .navbar {
        padding-right: 30px;
    }
    .appBar-right {
        right: 25px;
    }
    .appBar-left h1 {
        margin: 0px;
        padding: 0px;
        padding-left: 30px;
        line-height: 48px;
        text-align: center;
        color: white;
        font-size: 15px;
        letter-spacing: 0.46px;
        font-weight: 600;
    }
    .info1 {
        display: inline-block;
        width: calc(50% - 1px);
        height: 100px;
    }
    .info3 {
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 1px);
        height: 100px;

        background: white;
    }
    .info3 .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100px;
        background: white;
    }
    .info3 .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100px;
        justify-content: flex-start;
        background: white;
    }
    .leftLine {
        display: none;
    }
    .rightLine {
        display: inline-block;
        border-right: 1px black solid;
        height: 70px;
        opacity: 0.44;
        background-color: #d8d8d8;
        vertical-align: middle;
        margin: 0px 0px 70px 0px;
    }
    .top3 {
        padding-top: 32px;
    }
    .logo {
        margin-left: 5px;
        margin-right: 14px;
    }
}

@media only screen and (max-width: 650px) {
    .popUp {
        position: fixed;
        top: calc(50% - 30vh);
        left: calc(50% - 47.5vw);
        padding: 0px 0px 2px 0px;
        width: 95vw;
        height: 60vh;
        border-radius: 4px;
        background-color: white;
        z-index: 98;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }

    .cardTitleText {
        margin: 5px 0px 0px 10px;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #000000;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 10px;
        font-weight: 400;
        font-size: 9px;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .cards {
        padding-top: 0px;
    }
    .nestedTitle {
        font-weight: 600;
        font-size: 14px;
    }
    .projectSelect {
        width: 250px;
    }
    .navbar {
        padding-right: 30px;
    }
    .appBar-right {
        right: 25px;
    }
    .appBar-left h1 {
        margin: 0px;
        padding: 0px;
        padding-left: 30px;
        line-height: 48px;
        text-align: center;
        color: white;
        font-size: 15px;
        letter-spacing: 0.46px;
        font-weight: 600;
    }
    .info1 {
        display: inline-block;
        width: calc(50% - 1px);
        height: 100px;
    }
    .info3 {
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 1px);
        height: 100px;

        background: white;
    }
    .info3 .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100px;
        background: white;
    }
    .info3 .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100px;
        justify-content: flex-start;
        background: white;
    }
    .leftLine {
        display: none;
    }
    .rightLine {
        display: inline-block;
        border-right: 1px black solid;
        height: 70px;
        opacity: 0.44;
        background-color: #d8d8d8;
        vertical-align: middle;
        margin: 0px 0px 70px 0px;
    }
    .top3 {
        padding-top: 32px;
    }
    .logo {
        margin-left: 5px;
        margin-right: 14px;
    }
    .s1 {
        width: 29%;
    }
}

@media only screen and (max-width: 550px) {
    .cardTitleText {
        margin: 5px 0px 0px 10px;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #000000;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 10px;
        font-weight: 400;
        font-size: 9px;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .cards {
        padding-top: 0px;
    }
    .clearBut,
    .subBut {
        margin-top: 20px;
        display: block;
    }
    .nestedTitle {
        font-weight: 600;
        font-size: 14px;
    }
    .projectSelect {
        width: 160px;
    }
    .navbar {
        padding-right: 25px;
    }
    .appBar-right {
        right: 25px;
    }
    .appBar-left h1 {
        margin: 0px;
        padding: 0px;
        padding-left: 24px;
        line-height: 48px;
        text-align: center;
        color: white;
        font-size: 15px;
        letter-spacing: 0.46px;
        font-weight: 600;
    }
    .info1 {
        display: inline-block;
        width: 100%;
        height: 100px;
    }
    .info3 {
        display: none;
    }

    .leftLine {
        display: none;
    }
    .rightLine {
        display: none;
    }
    .popUp {
        position: fixed;
        top: calc(50% - 30vh);
        left: calc(50% - 45vw);
        padding: 0px 0px 2px 0px;
        width: 90vw;
        height: 60vh;
        border-radius: 4px;
        background-color: white;
        z-index: 98;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }
    .s1 {
        width: 45%;
    }
}

@media only screen and (max-width: 450px) {
    .cardTitleText {
        margin: 5px 0px 0px 10px;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #000000;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 10px;
        font-weight: 400;
        font-size: 9px;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .cards {
        padding-top: 0px;
    }
    .nestedTitle {
        font-weight: 600;
        font-size: 14px;
    }
    .projectSelect {
        width: 130px;
    }
    .navbar {
        padding-right: 25px;
    }
    .appBar-right {
        right: 25px;
    }
    .appBar-left h1 {
        margin: 0px;
        padding: 0px;
        padding-left: 24px;
        line-height: 48px;
        text-align: center;
        color: white;
        font-size: 15px;
        letter-spacing: 0.46px;
        font-weight: 600;
    }
    .s1 {
        width: 44%;
    }
}

@media only screen and (max-width: 400px) {
    .cardTitleText {
        margin: 5px 0px 0px 10px;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #000000;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 10px;
        font-weight: 400;
        font-size: 9px;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .cards {
        padding-top: 0px;
    }
    .nestedTitle {
        font-weight: 600;
        font-size: 14px;
    }
    .projectSelect {
        width: 110px;
    }
    .navbar {
        padding-right: 22px;
    }
    .appBar-right {
        right: 20px;
    }
    .appBar-left h1 {
        margin: 0px;
        padding: 0px;
        padding-left: 24px;
        line-height: 48px;
        text-align: center;
        color: white;
        font-size: 15px;
        letter-spacing: 0.46px;
        font-weight: 600;
    }
    .s1 {
        width: 42%;
        text-align: center;
    }
}
@media only screen and (max-width: 350px) {
    .cardTitleText {
        margin: 5px 0px 0px 8px;
        font-size: 9px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #000000;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 8px;
        font-weight: 400;
        font-size: 8px;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .nestedTitle {
        font-weight: 600;
        font-size: 14px;
    }
    .projectSelect {
        width: 80px;
    }
    .navbar {
        padding-right: 20px;
    }
    .appBar-right {
        right: 20px;
    }
    .appBar-left h1 {
        margin: 0px;
        padding: 0px;
        padding-left: 24px;
        line-height: 48px;
        text-align: center;
        color: white;
        font-size: 14px;
        letter-spacing: 0.46px;
        font-weight: 600;
    }
    .s1 {
        width: 100%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 650px) {
    .info {
        display: block;
        width: 100%;
        max-width: 1355px;
        height: 100px;
    }

    .info1 {
        display: inline-block;
        width: 50%;
        height: 100px;
    }
    .info1 .wrapper {
        display: flex;
        width: 100%;
        height: 100%;

        align-items: center;
    }
    .logo {
        width: 160px;
        height: 55px;
        margin-right: 30px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .info1 p {
    }

    .info2 {
        display: none;
        vertical-align: top;
        width: 50%;
        height: 100px;

        background: white;
    }

    .info3 {
        display: inline-block;
        vertical-align: top;
        width: calc(50% -1px);
        height: 100px;

        background: white;
    }
    .top3,
    .bottom3 {
        padding-left: 35px;
    }
    .smallShell {
        display: flex;

        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        margin: 0px 0px 0px 0px;
        padding: 10px 0px 0px 0px;
        color: white;
    }
    .small {
        width: 31.25%;
        position: relative;
        background: white;
        border-radius: 4px;
        margin: 20px 0px 0px 0px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }
    .mediumShell {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        margin: 0px 0px 0px 0px;
        padding: 10px 0px 0px 0px;
        color: white;
    }
    .medium {
        width: 47.5%;

        background: white;
        border-radius: 4px;
        margin: 20px 0px 0px 0px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }
    .bigShell {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        margin: 0px 0px 0px 0px;
        padding: 10px 0px 0px 0px;
        color: white;
    }
    .big {
        width: 96%;

        background: white;
        border-radius: 4px;
        margin: 20px 0px 0px 0px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }
    .chartTitle {
        width: 100%;
        height: 35px;
        border: solid 1px #f2f2f2;
        background-color: #fafafa;
    }
    .chartTitleText {
        margin: 0px 0px 0px 18px;
        line-height: 35px;
        font-size: 11.4px;
        font-weight: 600;
        letter-spacing: 0.7px;
        color: #000000;
    }
    .card {
        width: 30%;
        background: white;
        border-radius: 4px;
        margin: 10px 0px 0px 0px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);height: 140px;
    }
    .cardTitle {
        display: block;
        width: 100%;
        height: 70px;
        border: solid 1px #f2f2f2;
        background-color: #fafafa;
    }
    .cardVal {
        display: block;
        width: 100%;
        height: 70px;
        background-color: white;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 18px;
        font-weight: 400;
        font-size: 11px;
    }

    .cardTitleText {
        margin: 5px 0px 0px 18px;
        font-size: 11.4px;
        font-weight: 600;
        letter-spacing: 0.7px;
        color: #000000;
    }
}

@media only screen and (min-width: 1000px) {
    .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0px 0px 0px 20px;
        padding: 0px 0px 0px 0px;
        background-color: #fafafa;
    }
    .card {
        width: 15.1685%;
        background: white;
        border-radius: 4px;
        margin: 10px 20px 0px 0px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);height: 140px;
    }
    .cardTitle {
        display: block;
        width: 100%;
        height: 70px;
        border: solid 1px #f2f2f2;
        background-color: #fafafa;
    }
    .cardVal {
        display: block;
        width: 100%;
        height: 70px;
        background-color: white;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 8px;
        font-weight: 400;
        font-size: 10px;
    }

    .cardTitleText {
        margin: 3px 0px 0px 8px;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #000000;
    }
    .smallShell {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0px 0px 0px 20px;
        padding: 0px 0px 0px 0px;
        color: white;
    }
    .smallChartShell {
        width: 100%;
        height: calc(100% - 35px);
    }
    .small {
        width: 23.479%;
        height: calc((1315px * 0.23479) * 0.637);
        background: white;
        border-radius: 4px;
        margin: 20px 20px 0px 0px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }
    .mediumShell {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0px 0px 0px 20px;
        padding: 0px 0px 0px 0px;
        color: white;
    }
    .mediumChartShell {
        width: 100%;
        height: calc(100% - 35px);
    }
    .medium {
        width: 31.293%;
        height: calc((1315px * 0.31835) * 0.637);
        background: white;
        border-radius: 4px;
        margin: 20px 20px 0px 0px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }
    .bigShell {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 0px 0px 0px 20px;
        padding: 0px 0px 0px 0px;
        color: white;
    }
    .bigChartShell {
        width: 100%;
        height: calc(100% - 35px);
    }
    .big {
        width: 47.005%;
        height: calc((1315px * 0.48501) * 0.6309);
        background: white;
        border-radius: 4px;
        margin: 20px 20px 0px 0px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }
    .chartTitle {
        width: 100%;
        height: 35px;
        border: solid 1px #f2f2f2;
        background-color: #fafafa;
    }
    .chartTitleText {
        margin: 0px 0px 0px 18px;
        line-height: 35px;
        font-size: 11.4px;
        font-weight: 600;
        letter-spacing: 0.7px;
        color: #000000;
    }

    .info {
        display: block;
        width: calc(100% - 2px);
        max-width: 1355px;
        height: 100px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.03);
        border: solid 1px #f2f2f2;
    }
    .leftLine {
        display: inline-block;
        border-left: 1px black solid;
        height: 70px;
        opacity: 0.44;
        background-color: #d8d8d8;
        vertical-align: top;
        margin: 15px 0px 0px 0px;
    }

    .info1 {
        display: inline-block;
        width: calc(25% - 1px);
        height: 100px;
    }
    .info1 .wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: white;
    }
    .logo {
        width: 160px;
        height: 55px;
        margin: 0px;
        margin-right: 10px;
        margin-left: 10px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .nestedTitle {
        font-weight: 600;
        font-size: 13px;
    }
    .info2 {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        height: 100px;
    }

    .info2 .wrapper {
        display: flex;
        width: calc(100% - 20px);
        height: 100%;
    }

    .info3 {
        display: inline-block;
        vertical-align: top;
        width: calc(25% - 1px);
        height: 100px;
    }
    .rightLine {
        display: inline-block;
        border-right: 1px black solid;
        height: 70px;
        opacity: 0.44;
        background-color: #d8d8d8;
        vertical-align: middle;
        margin: 0px 0px 30px 0px;
    }
}

@media only screen and (min-width: 1100px) {
    .cardTitle {
        display: block;
        width: 100%;
        height: 70px;
        border: solid 1px #f2f2f2;
        background-color: #fafafa;
    }
    .cardVal {
        display: block;
        width: 100%;
        height: 70px;
        background-color: white;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 8px;
        font-weight: 400;
        font-size: 10px;
    }

    .cardTitleText {
        margin: 3px 0px 0px 8px;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #000000;
    }
}

@media only screen and (min-width: 1200px) {
    .cardTitle {
        display: block;
        width: 100%;
        height: 70px;
        border: solid 1px #f2f2f2;
        background-color: #fafafa;
    }
    .cardVal {
        display: block;
        width: 100%;
        height: 70px;
        background-color: white;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 8px;
        font-weight: 400;
        font-size: 10px;
    }

    .cardTitleText {
        margin: 3px 0px 0px 8px;
        font-size: 11.4px;
        font-weight: 600;
        letter-spacing: 0.4px;
        color: #000000;
    }
}

@media only screen and (min-width: 1300px) {
    .cardTitle {
        display: block;
        width: 100%;
        height: 70px;
        border: solid 1px #f2f2f2;
        background-color: #fafafa;
    }
    .cardVal {
        display: block;
        width: 100%;
        height: 70px;
        background-color: white;
    }
    .cardValue {
        display: inline-block;
        width: calc(100% - 42px);
        /* margin: 10px 0px 0px 15px; */
        font-weight: 600;
        font-size: 19px;
        padding: 0 0 0 10px;
    }
    .cardUnit {
        display: block;
        width: 100%;
        margin: 5px 0px 0px 15px;
        font-weight: 400;
        font-size: 10px;
    }

    .cardTitleText {
        margin: 3px 0px 0px 15px;
        font-size: 11.4px;
        font-weight: 600;
        letter-spacing: 0.4px;
        color: #000000;
    }
}

.chartTitleTextPop {
    display: inline-block;
    margin: 0px 0px 0px 6px;
    line-height: 35px;
    font-size: 11.4px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #000000;
    width: calc(100% - 34px);
}

.close {
    z-index: 999999999999;
}
.full {
    width: calc(96% - 4px);
}
.tableChart {
    width: calc(100% - 40px);
    margin: 20px 0px 0px -18px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
}

th,
td {
    font-family: "Fira Sans", sans-serif;
    text-align: left !important;
}
th,
tr td {
    height: 35px !important;
}
.projectSelect {
    font-family: "Fira Sans", sans-serif;
}
.bigHeaderText,
.cardHeaderText {
    font-size: 12px;
    text-decoration: underline;
}

.cardTitletext {
    height: 70px;
    font-size: 12px;
}

th,
tr,
td {
    font-size: 12px;
    border-style: dashed;
}
.overLay1 {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 11;
    -moz-opacity: 0.8;
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.reportsHeader {
    display: block;
    width: 100%;
    height: 40px;
    margin: 42px 0px 0px 0px;
}
.reportsHeaderText {
    display: block;
    line-height: 40px;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 20px;

    font-size: 13.3px;
    font-weight: 600;
    letter-spacing: 0.73px;
}

.reportsTitle {
    width: 80%;
    height: 35px;
    border: solid 1px #f2f2f2;
    background-color: white;
    border-radius: 4px;

    margin-bottom: 15px;
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1); */
}
.reportsTitleText {
    display: inline-block;
    margin: 0px 0px 0px 18px;
    line-height: 35px;
    font-size: 11.4px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #000000;
}

.reportsDownload {
    float: right;
    height: 35px;
    margin-right: 15px;
    cursor: pointer;
}

.dropGrommet {
    z-index: 999;
}

.popChart {
    height: calc(100% - 35px);
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  color:black;
  z-index:999999999999;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}